import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable({
    providedIn: 'root'
})
export class DisableApiResponseInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request
            .clone({
                url: request.url,
                params: request.params.append('disable-api-response-middleware', '1')
            });
        return next.handle(request);
    }
}
