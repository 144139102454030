// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const client_id = 'localhost-nekoplus';
const server = {
  local: 'http://localhost',
  // public: 'http://192.168.110.2',
  public: 'http://192.168.110.2'
};

export const environment = {
  production: false,
  // version: env.npm_package_version + '-dev',
  serverUrl: 'https://nekocareplus.yamatothai.com',
  // serverUrl: server.local + ':60115',
  // identityNekoPlus: server.local + ':60001',
  identityNekoPlus: 'https://nekocareplus-auth.yamatothai.com',
  project: server.public + ':52105',
  // project: server.public + ':50105',
  storage: server.local + ':60003',
  // user: server.local + ':50006',
  user: 'https://nekocareplus-u.yamatothai.com',
  authUrl: 'https://nekocareplus-auth.yamatothai.com',
  authEndPoint: '/connect/authorize?',
  defaultLanguage: 'en-US',
  supportedLanguages: [
    { title: 'EN', value: 'en-US', icon: 'assets/icon/nation/uk.png' },
    { title: 'TH', value: 'th-TH', icon: 'assets/icon/nation/th.png' },
    { title: 'JP', value: 'jp-JP', icon: 'assets/icon/nation/jp.png' },
  ],
  identityConfigs: {
    identityUrl: 'https://nekocareplus-auth.yamatothai.com',
    projectId: 'eeef56d6-0a1f-4053-9ed5-ef99e4a263e4',
    customerId: '',
    authorizeEndpoint: '/connect/authorize?',
    queryParams: {
      response_type: encodeURI('id_token token'),
      client_id: encodeURI(client_id),
      redirect_uri: encodeURI(location.origin),
      post_logout_redirect_uri: encodeURI(location.origin),
      scope: encodeURI('openid profile nekoplus'),
      nonce: '',
      state: '',
    },
    authParam: {
      client_id: encodeURI(client_id),
      client_secret: 'wms1234',
      scope: 'openid profile nekoplus offline_access',
      grant_type: 'password',
    },
    refreshTokenParam: {
      client_id: encodeURI(client_id),
      client_secret: 'wms1234',
      grant_type: 'refresh_token',
    },
  },
  encryptConfigs: {
    enable: false,
    pwd: 'OCYzM2xpZkxJSDNqZmcqRWY=',
  },
  config: {
    omiseKey: 'pkey_test_5nn0jptziha8avkuzlt'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
