import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./component/home/home.module').then(m => m.HomeModule)
  }
  , {
    path: 'interface',
    loadChildren: () => import('./component/line-interface/line-interface.module').then(m => m.LineInterfaceModule)
  }
  , {
    path: 'auth',
    loadChildren: () => import('./component/line-interface2/line-interface2.module').then(m => m.LineInterface2Module)
  }
  , {
    path: 'confirm-email',
    loadChildren: () => import('./component/confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule)
  }
  , {
    path: 'account-register',
    loadChildren: () => import('./component/account-register/account-register.module').then(m => m.AccountRegisterModule)
  }
  , {
    path: 'page/warraty-detail',
    loadChildren: () => import('./page/warranty-detail/warranty-detail.module').then(m => m.WarrantyDetailModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
