import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EncryptionService } from 'src/app/service/encryption.service';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root'
})
export class EncodeInterceptor implements HttpInterceptor {

  constructor(private encryptService: EncryptionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET'
      && request.method !== 'DELETE'
      && !request.url.startsWith(environment.storage)
    ) {
      try {
        if (typeof (request.body) === 'object') {
          request = request.clone({
            body: this.encryptService.encrypt(request.body)
          });
        }
      } catch (error) {
        console.log(error);
      }

    }
    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.ok && event.body && event.body.Result) {
          if (typeof (event.body.Result) === 'string'
          ) {
            try {
              const newBody = this.encryptService.decrypt(event.body);
              const newEvent = new HttpResponse({
                body: newBody
                , headers: event.headers
                , status: event.status
                , statusText: event.statusText
                , url: event.url
              });
              event = newEvent;
            } catch (error) {
              return event;
            }
          }
        }
      }
      return event;
    }));
  }

}
