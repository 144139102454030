import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/service/storage.service';
import { CookieService } from 'ngx-cookie-service';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    private token: string;

    constructor(private cookieService: CookieService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('Authorization');
        // this.token = this.cookieService.get('access_token');
        this.token = (localStorage.getItem('access_token')+'').replace(/"/g, '')
        request = request
            .clone({
                url: request.url,
                headers: request.headers
                    .append('Authorization', 'Bearer ' + decodeURIComponent(this.token)),
            });
        return next.handle(request);
    }

}
