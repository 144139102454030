import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
// tslint:disable-next-line: max-line-length


/**
 * Adds a default error handler to all requests.
 */

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    // private identityService: IdentityService,
    ) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
    }
    if (response instanceof HttpErrorResponse) {
      switch (response.status) {
        case 401:
          this.HandleError(response);
          break;
        case 403:
          this.HandleError(response);
          break;
        case 500:
          const errorMessage = (response.error.ResponseException ?
            response.error.ResponseException.ExceptionMessage
            : response.error.Message);

          break;
        default:
          break;
      }
    }
    throw response;
  }

  private HandleError(error: any) {
    console.log(error);
    if (error.status === 403) {
      console.log('Forbidden');
    } else if (error.status === 401) {
      console.log('Unauthorized');
      // this.identityService.getAccessTokenByRefreshToken();
    }
  }

}
